import React, { useContext } from "react";
import Title from "../Components/Title";
import styled from "styled-components";
import profilePicture from "../images/profilePicture.png";
import Fade from "react-reveal/Fade";
import progressBars from "../data/progressBars";
import SkillSection from "../Components/SkillSection";
import Zoom from "react-reveal/Zoom";
import { Helmet } from "react-helmet";
import { NavLink, useLocation } from "react-router-dom";

import { ThemeContext } from "../context";

function AboutPage() {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  const location = useLocation();
  // Check if the current path is the homepage
  const isHomepage = location.pathname === "/";
  const isAboutPage = location.pathname === "/about";

  return (
    <AboutPageStyled darkMode={darkMode}>
      <Helmet>
        <title>Amrindra | About Me</title>
        <meta name="description" content="Portfolio" />
        <link rel="canonical" href="/about" />
      </Helmet>

      <div className="text-title">
        <Title tittle={"About Me"} span={"About Me"} />
      </div>

      <div className="imageSection">
        <div className="image">
          <Zoom>
            <img src={profilePicture} alt="Amrindra Ouk profile picture" />
          </Zoom>
        </div>

        <div className="about-info">
          {!isHomepage && (
            <h4>
              I am{" "}
              <span style={{ color: darkMode ? "#EC255A" : "#4b5a63" }}>
                Amrindra,
              </span>
            </h4>
          )}

          <Fade right>
            <ul className="summary" style={{ color: darkMode && "white" }}>
              <li>
                Driven by a deep passion for coding, I excel in analytical
                programming, mastering Python, JavaScript, and SQL to turn
                complex problems into elegant solutions.
              </li>
              <li>
                Comprehensive expertise across the SDLC spectrum, from design
                and development to testing, implementation, and ongoing support.
              </li>
              <li>
                Skilled in Python, SQL, HTML, CSS, JavaScript, and React, with a
                knack for crafting seamless and visually captivating user
                experiences.
              </li>
              <li>
                Experience in modernizing legacy codebases, including COBOL, by
                transforming them into efficient Python and SQL.
              </li>
              <li>
                Expert in designing and implementing responsive web designs,
                with a creative flair for custom animations that enhance user
                engagement.
              </li>
              <li>
                Adept at building robust presentation layers for web
                applications using cutting-edge technologies like HTML, CSS,
                JavaScript, and React.
              </li>
              <li>
                Proficient in Version Control Systems, including Git and GitHub,
                ensuring code quality and collaboration efficiency.
              </li>
            </ul>
          </Fade>

          {!isAboutPage && (
            <NavLink to="/about">
              <button className="learn-more-about-me-btn">
                Learn more about me
              </button>
            </NavLink>
          )}
        </div>
      </div>

      {!isHomepage && (
        <div className="more-about-me">
          <h3 className="more-about-me-title">My Summary:</h3>
          <p className="more-about-me-paragraph">
            I’ve been passionate about technology since I was young, starting
            with simple coding projects that sparked my love for
            problem-solving. Today, as a senior software engineer, I’m focused
            on converting a legacy COBOL codebase into Python and SQL, which
            allows me to combine my technical skills with a drive to modernize
            critical systems. <br /> <br />
            When I am not coding, I find joy in exploring productivity hacks,
            staying updated on new tech trends, and embracing the outdoors. I
            also cherish creating new adventures with my family, be it a hike or
            a weekend getaway. I firmly believe that cultivating a balance
            between work and hobbies keeps my mind sharp and open to fresh
            perspectives.
            <br /> <br />
            For me, technology is more than just a job, it’s a lifelong passion
            that fuels my curiosity and growth.
          </p>
        </div>
      )}

      <div>
        <Fade bottom>
          <Title tittle={"Tech Skills"} span={"Tech Skills"} />

          {/* **********Progress Bars Section************ */}
          <div className="skills-container">
            {progressBars.map((item, index) => {
              return (
                <SkillSection
                  key={index}
                  icon={item.icon}
                  progress={item.progress}
                  width={item.width}
                  skill={item.skill}
                />
              );
            })}
          </div>
        </Fade>
      </div>
    </AboutPageStyled>
  );
}

const AboutPageStyled = styled.div`
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  padding-bottom: 2.5rem;
  scroll-behavior: smooth;

  .imageSection {
    display: flex;
    font-size: 1rem;
    margin-top: 3rem;
    margin-bottom: 2rem;

    img {
      width: 250px;
      border-radius: 0.9rem;
    }

    .about-info {
      margin-left: 1rem;
      border-width: 3px;
      border-style: solid;
      border-image: ${({ darkMode }) =>
        darkMode
          ? "linear-gradient(to bottom, #ffffff, rgba(0, 0, 0, 0)) 1 100%"
          : "linear-gradient(to bottom, #352828, rgba(0, 0, 0, 0)) 1 100%"};
      padding: 0 25px;

      h4 {
        font-size: 3rem;
      }

      .summary {
        font-size: 1.1rem;
        font-weight: 400;
        color: #888;
        line-height: 1.7;

        li {
          list-style: square;
        }
      }

      .learn-more-about-me-btn {
        padding: 10px;
        border: 1px solid;
        ${({ darkMode }) => (darkMode ? "white" : "black")}
        border-radius: 2px;
        background-color: transparent;
        color: ${({ darkMode }) => (darkMode ? "white" : "black")};
        cursor: pointer;
        margin-top: 15px;
        z-index: 999;

        &:hover {
          cursor: pointer;
          background-color: #a7a7a7;
        }
      }
    }
  }

  .more-about-me {
    margin-top: 2rem;
    margin-bottom: 2rem;

    .more-about-me-title {
      padding-bottom: 20px;
      font-family: "DM Sans", sans-serif;
      font-size: 1.9rem;
    }

    .more-about-me-paragraph {
      padding-left: 25px;
      font-size: 1.1rem;
      line-height: 30px;
    }
  }

  .skills-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    row-gap: 1rem;
  }

  @media (max-width: 678px) {
    .imageSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 1rem;

      .image {
        margin-bottom: 20px;
      }

      .about-info {
        margin-left: 0;

        h4 {
          text-align: center;
          font-size: 2rem;
        }
      }

      .text-title {
        h3 {
          color: red;
        }
      }

      .paragraph {
        text-align: center;
      }
    }

    .skills-container {
      grid-template-columns: repeat(4, 1fr);
      column-gap: 1rem;
      margin-bottom: -1rem;

      .icon-percentage {
        justify-content: center;
      }

      .skill-bar {
        display: none;
      }
    }
  }

  .navLink_button {
    display: flex;
    justify-content: center;
  }
`;

export default AboutPage;
