import React, { useContext } from "react";
import styled from "styled-components";
import ReactGA from "react-ga";
import { ThemeContext } from "src/context";

const trackingId = "UA-209096337-2";
ReactGA.initialize(trackingId);
ReactGA.set({
  username: localStorage.getItem("userName"),
});

const eventTrack = (category, action, label) => {
  // console.log("GA event:", category, ":", action, ":", label);
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

function Card({ img, gitLink, title, desc, button, demo, siteLink, icon }) {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  return (
    <CardStyled>
      <div className={darkMode ? "card" : "card card-light-mode"}>
        <div className={`card-browser`}>
          <div className="card-circle"></div>
          <div className="card-circle"></div>
          <div className="card-circle"></div>
        </div>

        <div className="card-layer"></div>

        <div className="card-body">
          <div className="card-img">
            <a href={gitLink} target="_blank" rel="noreferrer">
              <img src={img} alt="card image" className="card-img" />
            </a>
          </div>
          <a href={siteLink} target="_blank" rel="noreferrer">
            <h2 className="card-title">{title}</h2>
          </a>
          <p className="card-description">{desc}</p>

          <div className="card-button-wrapper">
            {/* Some projects does't have demo so need to check if demo exist or not */}
            {demo && (
              <div className="see-demo">
                <a href={siteLink} target="_blank" rel="noreferrer">
                  <h3
                    onClick={eventTrack.bind(
                      this,
                      `${title}`,
                      `${title} Demo`,
                      "Demo"
                    )}
                  >
                    {demo}
                  </h3>
                </a>
              </div>
            )}

            {button && (
              <div className="btn-wrapper">
                <a href={gitLink} target="_blank" rel="noreferrer">
                  <button
                    className="card-btn"
                    onClick={eventTrack.bind(
                      this,
                      `${title}`,
                      `${title} GitHub Button`,
                      "GitHub"
                    )}
                  >
                    {button}
                    {icon}
                  </button>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </CardStyled>
  );
}

const CardStyled = styled.div`
  .card.card-light-mode {
    border: 2px solid lightgray;
    color: black;
  }

  .card {
    box-shadow: 3px 3px 4px 2px;
    transition: all 400ms ease-in;
    background: transparent;
    overflow: hidden;
    border-radius: 0.5rem;
    position: relative;
    z-index: 3;
    border: 2px solid white;

    .card-body {
      z-index: 2;
      position: relative;
    }

    .card-layer {
      z-index: 1;
      position: absolute;
      top: calc(100% - 2px);
      height: 100%;
      width: 100%;
      left: 0;
      background: linear-gradient(to left, #743ad5, #d53a9d);
      transition: 0.5s;
    }

    &:hover .card-layer {
      top: 0;
    }

    &:hover {
      cursor: pointer;
      top: 0;
      color: white;
    }

    h2 {
      font-size: 1.5rem;
      opacity: 0.7;
    }

    .card-img {
      height: 15rem;
      transition: all 2s ease;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &:hover {
          transform: scale(1.25) rotate(2deg);
        }
      }

      @media screen and (max-width: 684px) {
        width: 300px;
      }
    }

    .card-title {
      padding: 1rem;
      text-align: center;
      text-transform: capitalize;
    }

    .card-description {
      padding: 0 1rem;
      text-align: center;
      opacity: 0.7;
    }

    /* BUTTON SECTION */
    .card-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding: 20px;

      .card-btn {
        padding: 5px 10px;
        width: 100%;
        font-family: inherit;
        font-weight: bold;
        font-size: 1rem;
        border: none;
        border-radius: 5px;
        color: grey;
        background-color: #333;
        color: white;
        transition: 700ms;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        &:hover {
          background-color: black;
          cursor: pointer;
          transform: scale(1.04);
          transition: 700ms;
        }
      }
    }

    .card-browser {
      height: 20px;
      background-color: rgb(245, 245, 245);
      display: flex;
      align-items: center;
      position: sticky;
      z-index: 9;

      .card-circle {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        margin: 3px;
        background-color: #ff3cac;
        background-image: linear-gradient(
          225deg,
          #ff3cac 0%,
          #784ba0 50%,
          #2b86c5 100%
        );
      }
    }

    /* From Certificate Page */
    .cert-card {
      display: none;
    }
  }
`;

export default Card;
