import React, { useContext } from "react";
import styled from "styled-components";
import { ThemeContext } from "src/context";

function DownArrow() {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  return (
    <DownArrowStyled>
      <img
        src={
          darkMode
            ? "https://img.icons8.com/ios-filled/48/FFFFFF/mouse-scrolling.png"
            : "https://img.icons8.com/ios/48/1A1A1A/mouse-scrolling.png"
        }
        alt="mouse-scrolling"
        className="arrow-img"
      ></img>
    </DownArrowStyled>
  );
}

const DownArrowStyled = styled.div`
  display: flex;
  justify-content: center;

  img {
    margin-bottom: 30px;
    height: 50px;
    overflow-x: hidden;
    animation: animateDown infinite 1.2s;

    @keyframes animateDown {
      0%,
      20%,
      50%,
      80%,
      100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(5px);
      }
      60% {
        transform: translateY(3px);
      }
    }
  }
`;
export default DownArrow;
