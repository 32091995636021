import ITAutomation from "../images/google-IT-automation.PNG";
import ITSupport from "../images/google-IT-Support.PNG";
import Python from "../images/Python3.png";
import FullStack from "../images/Full-Stack-Web-Dev.PNG";
import ztm from "../images/zero-to-mastery.png";
import metaFrontEndDeveloper from "../images/Meta-Frontend-Cert.png";
import metaDatabaseEngineer from "../images/database_engineer_cert.PNG";

const certificateDate = [
  {
    id: "202419481987645671-Generalist-SWE",
    img: "https://github.com/user-attachments/assets/512b0936-0260-4ef7-927f-10fca5c077de",
    link: "https://www.credly.com/badges/deabf199-5192-49f6-80fc-74db33d31909/public_url",
  },
  {
    id: "202419481987645672-Full-Stack-Engineer",
    img: "https://github.com/user-attachments/assets/d229dc76-a158-4657-a73e-5a9acf60f28a",
    link: "https://www.credly.com/badges/b1883724-8c79-4ecd-8c5c-0d3cfb5bf3cd/public_url",
  },
  {
    id: "2023-Front-End-Developer",
    img: "https://github.com/user-attachments/assets/5b81d94d-7c12-456d-8e1e-52faa55d328f",
    link: "https://www.credly.com/badges/d7da4b23-f431-4fe7-88a2-844d98a1dc17/linked_in?t=rr0a6n",
  },
  {
    id: "202419481987645673-Backend-Developer",
    img: "https://github.com/user-attachments/assets/0f27b098-08e5-46e1-8dbe-7aa0e2960678",
    link: "https://www.credly.com/badges/8e8e61ac-6b9b-47de-a9db-03c67b1c651d/public_url",
  },
  {
    id: "202419481391231-Database-Engineer",
    img: metaDatabaseEngineer,
    link: "https://www.credly.com/badges/263330e1-ad75-4792-9569-b479590ad180/public_url",
  },
  {
    id: "202419481987645674-React-Navtive",
    img: "https://github.com/user-attachments/assets/7fb02157-c12e-40ab-8795-7440ee25e977",
    link: "https://coursera.org/share/3da6278d021f533eb13fe2f455d67114",
  },
  {
    id: "202419481987645675-iOS-Developer",
    img: "https://github.com/user-attachments/assets/789bdcc6-1d79-490c-a78b-5c638f32f5b0",
    link: "https://www.credly.com/badges/87da9779-5cff-40f1-a133-7c868ff22148/public_url",
  },
  {
    id: 1,
    img: ztm,
    // link: "https://www.udemy.com/certificate/UC-13abd32a-a41f-43c6-a5a1-b14c7ff486a8/",
    // title: "Complete React Developer 2023",
    // desc: "React | Redux | Typescript | GraphQL | Firebase | Testing ",
    // button: "View Credential",
  },
  {
    id: 12,
    img: FullStack,
    link: "https://www.udemy.com/certificate/UC-13abd32a-a41f-43c6-a5a1-b14c7ff486a8/",
    // title: "Full Stack Web Development",
    // desc: "Full-Stack web developer with HTML5 | CSS3 | JavaScript | NodeJS | React | MongoDB | SQL | UI/UX | RESTful API | Bootstrap",
    // button: "View Credential",
  },
  {
    id: 2,
    img: Python,
    link: "https://www.coursera.org/account/accomplishments/specialization/EQZN7RJU2YSL",
    // title: "Python 3",
    // desc: "Data Collection and Processing, Functions | Files, Dictionaries | Classes and Inheritance | Project: pillow | tesseract | and opencv",
    // button: "View Credential",
  },
  {
    id: 3,
    img: ITAutomation,
    link: "https://www.credly.com/badges/8019243c-46b4-48f6-90f5-bfac4bd60253?source=linked_in_profile",
    // title: "Google IT Automation With Python",
    // desc: "Crash Course on Python | Using Python to Interact with the Operating System | Troubleshooting and Debugging Techniques | Introduction to Git and GitHub | Configuration Management and the Cloud | Automating Real-World Tasks with Python",
    // button: "View Credential",
  },
  {
    id: 4,
    img: ITSupport,
    link: "https://www.credly.com/badges/5088bcad-3f3e-4ce0-b089-5f8e2bdf8dab?source=linked_in_profile",
    // title: "Google IT Support",
    // desc: "Technical Support Fundamentals | Operating Systems and You: Becoming a Power Userc | IT Security: Defense against the digital dark arts | System Administration and IT Infrastructure Services | The Bits and Bytes of Computer Networking",
    // button: "View Credential",
  },
];

export default certificateDate;
