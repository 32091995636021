import Card from "../Components/Card";
import React from "react";
import styled from "styled-components";
import Title from "../Components/Title";
import portfolioData from "../data/portfolioData";
import Slide from "react-reveal/Slide";
import { Helmet } from "react-helmet";

function ProjectPage() {
  return (
    <PortfolioPageStyled>
      <Helmet>
        <title>Amrindra | My Projects</title>
        <meta name="description" content="Portfolio" />
        <link rel="canonical" href="/project" />
      </Helmet>

      <Title tittle={"My Projects"} span={"My Projects"} />
      <div className="card-wrapper">
        <Slide bottom>
          {portfolioData.map((item) => (
            <Card
              key={item.id}
              img={item.img}
              gitLink={item.gitLink}
              title={item.title}
              demo={item.demo}
              siteLink={item.siteLink}
              desc={item.desc}
              button={item.button}
              icon={item.icon}
              // className="card-browser"
            />
          ))}
        </Slide>
      </div>

      {/* For the mobile screen */}
      <div className="card-wrapper-on-mobile">
        {portfolioData.map((item) => (
          <Card
            key={item.id}
            img={item.img}
            gitLink={item.gitLink}
            title={item.title}
            demo={item.demo}
            siteLink={item.siteLink}
            desc={item.desc}
            button={item.button}
            icon={item.icon}
          />
        ))}
      </div>
    </PortfolioPageStyled>
  );
}

const PortfolioPageStyled = styled.div`
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  padding-bottom: 2.5rem;
  scroll-behavior: smooth;

  .card-wrapper {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 20rem));
    gap: 2rem 2rem;
    justify-content: center;

    @media screen and (max-width: 1500px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem 2rem;
    }

    @media screen and (max-width: 868px) {
      grid-template-columns: repeat(auto-fit, minmax(12rem, 20rem));
      margin-top: 2rem;
    }

    @media screen and (max-width: 684px) {
      display: none;
    }
  }

  .card-wrapper-on-mobile {
    display: none;

    @media screen and (max-width: 684px) {
      display: flex;
      gap: 1.5rem;
      overflow-x: scroll;
      scroll-behavior: smooth;
      justify-content: flex-start;
      padding-right: 30px;
      margin-top: 2rem;
      padding-bottom: 1rem;
    }
  }

  .see-demo {
    h3 {
      border-radius: 5px;
      font-size: 1rem;
      padding: 6px 10px;
      background-color: #333;
      color: white;
      transition: 700ms;

      &:hover {
        background-color: black;
        cursor: pointer;
        transform: scale(1.04);
        transition: 700ms;
      }
    }
  }

  .card {
    height: 485px;
    /* border: 10px solid linear-gradient(to left, #743ad5, #d53a9d); */

    /* border-image: linear-gradient(to left, #743ad5, #d53a9d); */

    .card-browser {
      display: none;
    }
  }
`;

export default ProjectPage;
