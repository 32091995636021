import tesla from "../images/teslaClone.png";
import orderForm from "../images/OrderForm.png";
import toDoList from "../images/toDoList.png";
import onlineShop from "../images/24hoursOnlineShop.png";
import calculator from "../images/mortgage-tip-calculator.png";
import GithubIcon from "@mui/icons-material/GitHub";
import socialApp from "../images/social-app.png";
import adminDashboard from "../images/admin-dashboard.png";
import booking from "../images/booking.png";

const portfolioData = [
  {
    id: "2d3287edasfhweasdf",
    img: booking,
    gitLink: "https://github.com/Amrindra/staycation-app",
    title: "Booking App",
    demo: "Live Demo",
    siteLink: "https://staycation-booking-app.vercel.app/",
    desc: "This is a full stack booking app. You can register, login and become a host.Tech Stack: React.js, TailwindCSS, Node.js, MongoDB. ",
    button: "Code",
    icon: <GithubIcon style={{ width: "20px" }} />,
  },
  {
    id: "2d3287edasfhwe",
    img: adminDashboard,
    gitLink: "https://github.com/Amrindra/admin-dashboard",
    title: "Admin Dashoard personel project.",
    demo: "Live Demo",
    siteLink: "https://admin-dashboard-o6qo.vercel.app/",
    desc: "Tech Stack: React, Rechart.js, SCSS, Express.js for a dummy API data",
    button: "Code",
    icon: <GithubIcon style={{ width: "20px" }} />,
  },
  {
    id: "2d32",
    img: socialApp,
    gitLink: "https://github.com/Amrindra/social-app",
    title: "DevFam Social App",
    demo: "Live Demo",
    siteLink: "https://dev-fam-social-app.vercel.app/",
    desc: "It is my ongoing personal project. It's not completely done yet. Tech Stack: React, Redux, SCSS, MUI, Node, & MongoDB.",
    button: "Code",
    icon: <GithubIcon style={{ width: "20px" }} />,
  },
  {
    id: "szdgfas",
    img: "https://user-images.githubusercontent.com/70451928/233808083-68ed0992-ad3d-4e0f-83ed-97ef2e40ca15.png",
    gitLink: "https://github.com/Amrindra/Little-Lemon",
    title: "Little Lemon Capstone Project",
    demo: "Live Demo",
    siteLink: "https://little-lemon-weld.vercel.app/",
    desc: "This app is a capstone project from the Meta Frontend Developer Professional Certificte. Tech Stack: React and CSS. Yup form validation.",
    button: "Code",
    icon: <GithubIcon style={{ width: "20px" }} />,
  },
  // {
  //   img: "https://user-images.githubusercontent.com/70451928/206619268-420c5aac-b8b9-44f1-9eaf-8dc40eac9d5c.JPG",
  //   title: "Raya Sre Leap Investment",
  //   demo: "Live Demo",
  //   siteLink: "https://rayasreleap.com/",
  //   desc: "Built a landing page from scratch for a client to display their products and information using React.js and CSS",
  //   // button: "Code",
  //   // icon: <GithubIcon style={{ width: "20px" }} />,
  // },
  {
    id: "q3edf",
    img: "https://user-images.githubusercontent.com/70451928/206317742-745130e4-1d99-464f-a149-820dee41322f.png",
    gitLink: "https://github.com/Amrindra/ZTM-Clothing.git",
    title: "ZTM Clothing App",
    demo: "Live Demo",
    siteLink: "https://splendid-lamington-77f1a7.netlify.app",
    desc: "This app is a capstone project from the React developer 2023 course. It was built with React, Redux, Firebase, TypeScript, SCSS, and Stripe payment",
    button: "Code",
    icon: <GithubIcon style={{ width: "20px" }} />,
  },
  {
    id: "2d32aera",
    img: "https://user-images.githubusercontent.com/70451928/233808184-1279f8a2-d987-43c4-9c95-0b1c7d93d7d4.jpeg",
    gitLink: "https://github.com/Amrindra/Food-App",
    title: "Food App",
    demo: "Live Demo",
    siteLink: "https://food-app2022.vercel.app/",
    desc: "This Food app is built using React.js, Context, Styled component ",
    button: "Code",
    icon: <GithubIcon style={{ width: "20px" }} />,
  },
  {
    id: "2d32awerg",
    img: "https://user-images.githubusercontent.com/70451928/233807927-9f34f078-ff3c-4845-b8ec-0aac1ee3911e.png",
    gitLink:
      "https://github.com/Amrindra/Interactive-card-details-form-challenge.git",
    title: "Card Form Validation",
    demo: "Live Demo",
    siteLink: "https://credit-card-form-validation.netlify.app/",
    desc: "This project was a challenge that I completed from Frontend mentor website using React.js",
    button: "Code",
    icon: <GithubIcon style={{ width: "20px" }} />,
  },
  {
    id: "2d32sdhjdty",
    img: "https://user-images.githubusercontent.com/70451928/233808143-25a6b40e-992d-4f9c-9103-29f9118921cd.png",
    gitLink: "https://github.com/Amrindra/react-admin-dashboard/tree/main/",
    title: "Admin Dashboard",
    demo: "Live Demo",
    siteLink: "https://react-admin-dashboard-project.vercel.app/",
    desc: "This admin dashboard is built with React, SCSS, Context API. This app is not mobile-friendly",
    button: "Code",
    icon: <GithubIcon style={{ width: "20px" }} />,
  },
  {
    id: "2d32gjfyudtr",
    img: "https://user-images.githubusercontent.com/70451928/233807734-7f247c45-0b7a-4bce-b74a-f2067c3d81cf.png",
    gitLink: "https://github.com/Amrindra/Apple-Website-Clone",
    title: "Apple site clone",
    demo: "Live Demo",
    siteLink: "https://apple-clone-education.vercel.app/",
    desc: "This clone is for educational purposes only",
    button: "Code",
    icon: <GithubIcon style={{ width: "20px" }} />,
  },
  {
    id: "2d32jdxfgs",
    img: "https://user-images.githubusercontent.com/70451928/233808012-cfd4e80c-2eeb-4f6b-93e5-30010e9ec296.png",
    gitLink: "https://github.com/Amrindra/airbnd-clone",
    title: "Airbnb clone",
    demo: "Live Demo",
    siteLink: "https://csb-fkc4ik-5sk1he337-amrindra.vercel.app/",
    desc: "This clone is for educational purposes only",
    button: "Code",
    icon: <GithubIcon style={{ width: "20px" }} />,
  },
  {
    id: "2d32ashsrf",
    img: "https://user-images.githubusercontent.com/70451928/233807834-a744aa73-d61c-4f4e-ad7e-ac8b39952e2a.png",
    gitLink: "https://github.com/Amrindra/Pizza-App",
    title: "Pizza App",
    demo: "Live Demo",
    siteLink: "https://online-pizza-app.vercel.app/",
    desc: "This Pizza App is built with Next.js, MongoDB/Mongoose, Redux ",
    button: "Code",
    icon: <GithubIcon style={{ width: "20px" }} />,
  },
  {
    id: "2d32hjkscferw",
    img: tesla,
    gitLink: "https://github.com/Amrindra/TeslaClone",
    title: "Tesla Clone Website",
    demo: "Live Demo",
    siteLink: "https://tesla-clone-site-unreal.netlify.app/",
    desc: "This Tesla clone is built with ReactJS and Styled Components. This clone is for educational purposes only ",
    button: "Code",
    icon: <GithubIcon style={{ width: "20px" }} />,
  },
  {
    id: "2d32adgwersx",
    img: onlineShop,
    gitLink: "https://github.com/Amrindra/24hours-OnlineShop",
    title: "24hours Online Shop",
    demo: "Live Demo",
    siteLink: "https://24hours-online-shop.netlify.app/",
    desc: "This online website is built with ReactJS, CSS, Context API and Firebase. It is just for personel project only.",
    button: "Code",
    icon: <GithubIcon style={{ width: "20px" }} />,
  },
  {
    id: "2d32;kjhasdfh",
    img: calculator,
    gitLink: "https://github.com/Amrindra/mortgage-and-tip-calculator-app",
    title: "Mortgage & Tip Calculator",
    demo: "Live Demo",
    siteLink: "https://mortgage-and-tip-calculator.vercel.app/",

    desc: "These Mortgage and Tip calculators are built with React.js.",
    button: "Code",
    icon: <GithubIcon style={{ width: "20px" }} />,
  },
  // {
  //   img: orderForm,
  //   gitLink:
  //     "https://github.com/Amrindra/Visual-Basic-Application-with-MS-Acess-Order-Form",
  //   title: "MS Access Order Form",
  //   desc: "I created this order form to help the team enter orders and keep track of the data which store in the database.",
  //   button: "Code",
  //   icon: <GithubIcon style={{ width: "20px" }} />,
  // },

  {
    id: "2d32p[wecvsd",
    img: toDoList,
    gitLink: "https://github.com/Amrindra/ToDo-List-Project",
    title: "ToDo List",
    desc: "This Todo list app is built using HTML, CSS, EJS, Node.js, and MongoDB",
    button: "Code",
    icon: <GithubIcon style={{ width: "20px" }} />,
  },
];

export default portfolioData;
