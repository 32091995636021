import styled from "styled-components";
import "./styles.css";
import { Routes, Route } from "react-router-dom";
import AboutPage from "./Pages/AboutPage";
import ProjectPage from "./Pages/ProjectPage";
import CertificatePage from "./Pages/CertificatePage";
import ContactPage from "./Pages/ContactPage";
import { useState, useContext, useEffect } from "react";
import ChangeTheme from "./Components/ChangeTheme";
import { ThemeContext } from "./context";
import { useLocation } from "react-router";
import ReactGA from "react-ga";
import Homepage from "./Pages/HomePage";
import Cursor from "./Components/Cursor";
import Sidebar from "./Components/Sidebar";
import { motion } from "framer-motion";
import ToggleButton from "./Components/ToggleButton";

// Setting up google analytics
const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;

export const usePageView = () => {
  let location = useLocation();

  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize(TRACKING_ID);
      window.GA_INITIALIZED = true;
    }
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);
};

// this ScrollToTop function is used to handle the page position when we switch to the new page it will remain on the top
function ScrollToTop(props) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
}

function App() {
  const [toggle, setToggle] = useState(false);

  const handleClick = () => setToggle(!toggle);

  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  //From the function above
  usePageView();

  return (
    <div
      className="app"
      style={{
        backgroundColor: darkMode && "#0c0c1d",
        color: darkMode && "white",
      }}
    >
      <Cursor />

      {/* Change dark and light mode component */}
      <ChangeTheme />

      {/* ****Passing State as a prop***** */}
      <Sidebar toggle={toggle} handleClick={handleClick} />

      <MainContentStyled>
        {/* Toggle sidebar button */}

        <motion.div
          className="toggle-button"
          animate={toggle ? "open" : "closed"}
        >
          <ToggleButton handleClick={handleClick} toggle={toggle} />
        </motion.div>

        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="project" element={<ProjectPage />} />
            <Route path="certificate" element={<CertificatePage />} />
            <Route path="contact" element={<ContactPage />} />
          </Routes>
        </ScrollToTop>
        {/* 
        <Link to="/" className="back-homepage-button">
          <button>back to home page</button>
        </Link> */}
      </MainContentStyled>
    </div>
  );
}

const MainContentStyled = styled.main`
  position: relative;
  margin-left: 16.3rem;
  min-height: 100vh;
  padding: 3rem 5rem;
  overflow: hidden;

  .toggle-button {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    margin-left: 0;
    padding: 2rem 1.5rem;

    .toggle-button {
      display: block;
      z-index: 999;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;

      button {
        /* z-index: 999; */
        position: fixed;
        width: 45px;
        height: 45px;
        border-radius: 100%;
        top: 15px;
        left: 25px;
        /* background-color: transparent; */
        background-color: white;
        border: none;
        cursor: pointer;
      }
    }
  }
`;

export default App;
