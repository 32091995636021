import React, { useContext } from "react";
import Title from "../Components/Title";
import certificateData from "../data/certificateData";
import Card from "../Components/Card";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Slide from "react-reveal/Slide";
import { ThemeContext } from "src/context";

function CertificatePage() {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  return (
    <CertificateStyled>
      <Helmet>
        <title>Amrindra | Certificates</title>
        <meta name="description" content="Portfolio" />
        <link rel="canonical" href="/certificate" />
      </Helmet>

      <div className="text-title">
        <Title
          tittle={"Professional Certs"}
          span={"Professional Certs"}
          className=""
        />
      </div>

      <div className="text-title-on-mobile">
        <Title tittle={"Certificates"} span={"Certificates"} className="" />
      </div>

      <div className="certicates-wrapper">
        <Slide bottom>
          {certificateData.map((item) => (
            <Card
              key={item.id}
              img={item.img}
              gitLink={item.link}
              // title={item.title}
              // desc={item.desc}
              // button={item.button}
              className="cert-card"
            />
          ))}
        </Slide>
      </div>

      {/* For mobile screen */}
      <div className="certicates-wrapper-on-mobile">
        {certificateData.map((item) => (
          <Card
            key={item.id}
            img={item.img}
            gitLink={item.link}
            // title={item.title}
            // desc={item.desc}
            // button={item.button}
            className="cert-card"
          />
        ))}
      </div>
    </CertificateStyled>
  );
}

const CertificateStyled = styled.div`
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  padding-bottom: 2.5rem;

  .text-title-on-mobile {
    display: none;
    @media screen and (max-width: 684px) {
      display: block;
    }
  }

  @media screen and (max-width: 684px) {
    .text-title {
      display: none;
    }
  }

  .certicates-wrapper {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 20rem));
    gap: 3rem 2.5rem;
    justify-content: center;

    //This card-btn is from Card component className
    .card-btn {
      width: 45%;
    }

    // Aceess card-title from CARD component directly
    .card-title {
      padding: 0rem;
    }

    // Aceess card-title from CARD component directly
    .card-button-wrapper {
      padding: 0px;
    }

    @media screen and (max-width: 1500px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem 2rem;
    }

    @media screen and (max-width: 830px) {
      grid-template-columns: repeat(auto-fit, minmax(12rem, 20rem));
    }

    @media screen and (max-width: 684px) {
      display: none;
    }
  }

  .certicates-wrapper-on-mobile {
    display: none;

    @media screen and (max-width: 684px) {
      display: flex;
      gap: 1.5rem;
      overflow-x: scroll;
      scroll-behavior: smooth;
      justify-content: flex-start;
      padding-right: 30px;
      margin-top: 2rem;
      padding-bottom: 1rem;
    }

    // Aceess card-title from CARD component directly
    .card-title {
      padding: 0rem;
    }

    // Aceess card-title from CARD component directly
    .card-button-wrapper {
      padding: 0px;
    }

    .card {
      /* height: 600px; */
    }
  }
`;

export default CertificatePage;
