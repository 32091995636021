const progressBars = [
  {
    icon: "https://img.icons8.com/color/100/000000/python--v1.png",
    progress: "90%",
    width: "90%",
    skill: "Python 3",
  },
  {
    icon: "https://img.icons8.com/external-bearicons-flat-bearicons/64/external-SQL-file-extension-bearicons-flat-bearicons.png",
    progress: "90%",
    width: "90%",
    skill: "SQL",
  },
  {
    icon: "https://img.icons8.com/color/100/000000/javascript--v1.png",
    progress: "90%",
    width: "90%",
    skill: "JavaScript",
  },
  {
    icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
    progress: "90%",
    width: "90%",
    skill: "React.JS",
  },
  {
    icon: "https://img.icons8.com/color/100/000000/html-5--v1.png",
    progress: "90%",
    width: "90%",
    skill: "HTML5",
  },
  {
    icon: "https://img.icons8.com/color/100/000000/css3.png",
    progress: "90%",
    width: "90%",
    skill: "CSS3",
  },
    {
    icon: "https://img.icons8.com/cute-clipart/64/react-native.png",
    progress: "80%",
    width: "80%",
    skill: "React Native",
  },
  {
    icon: "https://img.icons8.com/fluency/100/nextjs.png",
    progress: "85%",
    width: "85%",
    skill: "Next.JS",
  },
  {
    icon: "https://img.icons8.com/color/100/000000/redux.png",
    progress: "70%",
    width: "70%",
    skill: "Redux",
  },
  {
    icon: "https://img.icons8.com/color/100/000000/nodejs.png",
    progress: "70%",
    width: "70%",
    skill: "NodeJS + Express",
  },
  {
    icon: "https://img.icons8.com/color/100/000000/mongodb.png",
    progress: "80%",
    width: "80%",
    skill: "MongoDB + Mongoose",
  },
  {
    icon: "https://img.icons8.com/color/100/000000/bootstrap.png",
    progress: "90%",
    width: "90%",
    skill: "Bootstrap",
  },
  {
    icon: "https://img.icons8.com/external-tal-revivo-color-tal-revivo/100/000000/external-postman-is-the-only-complete-api-development-environment-logo-color-tal-revivo.png",
    progress: "85%",
    width: "85%",
    skill: "Postman",
  },

  {
    icon: "https://img.icons8.com/color/100/000000/git.png",
    progress: "80%",
    width: "80%",
    skill: "Git",
  },
  {
    icon: "https://img.icons8.com/color/100/000000/typescript.png",
    progress: "60%",
    width: "60%",
    skill: "TypeScript",
  },
  // {
  //   icon: "https://img.icons8.com/color/100/000000/c-plus-plus-logo.png",
  //   progress: "50%",
  //   width: "50%",
  //   skill: "C++",
  // },
  // {
  //   icon: "https://img.icons8.com/color/100/000000/c-programming.png",
  //   progress: "50%",
  //   width: "50%",
  //   skill: "C",
  // },
];

export default progressBars;
