import styled from "styled-components";
import React from "react";
import Navigation from "./Navigation";

function Sidebar({ toggle, handleClick }) {
  return (
    <SidebarSytled className={`${toggle && "nav-toggle"}`}>
      <Navigation handleClick={handleClick} />
    </SidebarSytled>
  );
}

const SidebarSytled = styled.div`
  width: 16.3rem;
  position: fixed;
  height: 100vh;
  overflow: hidden;
  background-color: #191d2b;
  box-shadow: 0 9px 15px rgba(0, 0, 0, 0.8);
  z-index: 999;
  /* transition: all 0.9s ease; */

  /* transition: transform 0.9s ease; */
  transition: all 1s cubic-bezier(0.68, -0.55, 0.27, 1.55);

  @media screen and (max-width: 1200px) {
    transform: translateX(-100%) scale(0.95);

    &.nav-toggle {
      transform: translateX(0) scale(1);
    }
  }
`;
export default Sidebar;
