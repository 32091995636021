const navLinksData = [
  {
    title: "< Home />",
    path: "/",
    className: "nav-item",
  },
  {
    title: "< About Me />",
    path: "/about",
    className: "nav-item",
  },
  {
    title: "< Projects />",
    path: "/project",
    className: "nav-item",
  },
  {
    title: "< Certificates />",
    path: "/certificate",
    className: "nav-item",
  },
  {
    title: "< Contact />",
    path: "/contact",
    className: "nav-item",
  },
];

export default navLinksData;
