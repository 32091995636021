import { useEffect, useState, useContext } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { ThemeContext } from "src/context";

const Cursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  useEffect(() => {
    const mouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", mouseMove);

    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  }, []);

  return (
    <CursorMovement darkMode={darkMode}>
      <motion.div
        className="cursor"
        animate={{ x: position.x + 10, y: position.y + 10 }}
      ></motion.div>
    </CursorMovement>
  );
};

export default Cursor;

const CursorMovement = styled.div`
  .cursor {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid ${({ darkMode }) => (darkMode ? "white" : "black")};
    position: fixed;
    z-index: 9999;

    @media screen and (max-width: 684px) {
      display: none;
    }
  }
`;
