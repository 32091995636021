// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "./context";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement); // Create root with ReactDOM.createRoot

root.render(
  <BrowserRouter>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </BrowserRouter>
);

// Updated React
// ReactDOM.render(
//   <BrowserRouter>
//     <ThemeProvider>
//       <App />
//     </ThemeProvider>
//   </BrowserRouter>,
//   rootElement
// );
